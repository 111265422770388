import { createGetKcContext } from "keycloakify/login";

export type KcContextExtension =
  // WARNING: It's important to keep in sync the extraThemeProperties declared in the package.json and this type definition.
  | { pageId: "login.ftl"; extraThemeProperties: { foo: string } }
  //   | { pageId: "my-extra-page-2.ftl"; someCustomValue: string }
  // NOTE: register.ftl is deprecated in favor of register-user-profile.ftl
  // but let's say we use it anyway and have this plugin enabled: https://github.com/micedre/keycloak-mail-whitelisting
  // keycloak-mail-whitelisting define the non standard ftl global authorizedMailDomains, we declare it here.
  | { pageId: "register-user-profile.ftl"; authorizedMailDomains: string[]; customPage: string, isInvitationFlow: boolean, execution: string }
  | { pageId: "login-idp-link-confirm.ftl"; brokerContext: { email: string } };

//NOTE: In most of the cases you do not need to overload the KcContext, you can
// just call createGetKcContext(...) without type arguments.
// You want to overload the KcContext only if:
// - You have custom plugins that add some values to the context (like https://github.com/micedre/keycloak-mail-whitelisting that adds authorizedMailDomains)
// - You want to add support for extra pages that are not yey featured by default, see: https://docs.keycloakify.dev/contributing#adding-support-for-a-new-page

export const { getKcContext } = createGetKcContext<KcContextExtension>({
  mockData: [
    {
      pageId: "login.ftl",
      locale: {
        //When we test the login page we do it in french
        currentLanguageTag: "en",
      },
      //Uncomment the following line for hiding the Alert message
      //"message": undefined
      //Uncomment the following line for showing an Error message
      //message: { type: "error", summary: "This is an error" }
    },
    {
      pageId: "info.ftl",
      message: {
        summary: "Info message and here is a list:",
      },
      requiredActions: ["UPDATE_PASSWORD", "UPDATE_PROFILE", "VERIFY_EMAIL"],
    },
    { pageId: "login-idp-link-confirm.ftl", brokerContext: { email: "fas@asd.dk" } },
    {
      pageId: "idp-review-user-profile.ftl",
      locale: {
        currentLanguageTag: "en",
      },
      profile: {
        attributes: [
          {
            name: "fullName",
            displayName: "Full name",

            validators: {
              length: {
                max: "255",
              },
              "person-name-prohibited-characters": {},
            },

            annotations: {
              inputType: "visible",
            },
            required: true,
          },
          {
            name: "phoneNumber",
            displayName: "Phone number",

            validators: {
              length: {
                max: "255",
              }
            },
            annotations: {
              inputType: "visible",
            },
            required: true,
          },
          {
            name: "username",
            displayName: "${username}",

            validators: {
              length: {
                max: "255",
              },
              "person-name-prohibited-characters": {},
            },

            annotations: {
              inputType: "hidden",
            },
            required: true,
          },
          {
            name: "lastName",
            displayName: "${lastName}",

            validators: {
              length: {
                max: "255",
              },
              "person-name-prohibited-characters": {},
            },

            annotations: {
              inputType: "hidden",
            },
            required: true,
          },
          {
            name: "firstName",
            displayName: "${firstName}",

            validators: {
              length: {
                max: "255",
              },
              "person-name-prohibited-characters": {},
            },

            annotations: {
              inputType: "hidden",
            },
            required: true,
          },

          {
            name: "email",
            displayName: "Email",
            validators: {
              email: {},
              length: {
                max: "255",
              },
            },
            required: true,
          },
          {
            name: "termsAndConditions",
            displayName: "Terms And Conditions",
            required: true,
            annotations: {
              inputType: "checkbox",
              inputLabel: "I agree to the <a href='https://zuuvi.com/terms-of-service' target='_blank'>Terms of Service</a> and <a href='https://www.zuuvi.com/privacy-policy' target='_blank'>Privacy Policy</a>.",
              inputValue: ".now?iso_local",
            },
          },
          {
            name: "userId",
            displayName: "Hidden userId field",
            annotations: {
              inputType: "hidden",
            },
          },
        ],
      },
    },
    {
      //NOTE: You will either use register.ftl (legacy) or register-user-profile.ftl, not both
      pageId: "register-user-profile.ftl",
      customPage: "account",
      execution: 'b91f05d8-f3e8-4595-a6f2-7d0a759b8edd',
      isInvitationFlow: true,
      locale: {
        currentLanguageTag: "en",
      },
      profile: {
        attributes: [
          {
            name: "fullName",
            displayName: "Full name",

            validators: {
              length: {
                max: "255",
              },
              "person-name-prohibited-characters": {},
            },

            annotations: {
              inputType: "visible",
            },
            required: true,
          },
          {
            name: "phoneNumber",
            displayName: "Phone number",

            validators: {
              length: {
                max: "255",
              }
            },
            annotations: {
              inputType: "visible",
            },
            required: true,
          },
          {
            name: "username",
            displayName: "${username}",

            validators: {
              length: {
                max: "255",
              },
              "person-name-prohibited-characters": {},
            },

            annotations: {
              inputType: "hidden",
            },
            required: true,
          },
          {
            name: "lastName",
            displayName: "${lastName}",

            validators: {
              length: {
                max: "255",
              },
              "person-name-prohibited-characters": {},
            },

            annotations: {
              inputType: "hidden",
            },
            required: true,
          },
          {
            name: "firstName",
            displayName: "${firstName}",

            validators: {
              length: {
                max: "255",
              },
              "person-name-prohibited-characters": {},
            },

            annotations: {
              inputType: "hidden",
            },
            required: true,
          },

          {
            name: "email",
            displayName: "Email",
            validators: {
              email: {},
              length: {
                max: "255",
              },
            },
            required: true,
          },
          {
            name: "termsAndConditions",
            displayName: "Terms And Conditions",
            required: true,
            annotations: {
              inputType: "checkbox",
              inputLabel: "I agree to the <a href='https://zuuvi.com/terms-of-service' target='_blank'>Terms of Service</a> and <a href='https://www.zuuvi.com/privacy-policy' target='_blank'>Privacy Policy</a>.",
              inputValue: ".now?iso_local",
            },
          },
          {
            name: "userId",
            displayName: "Hidden userId field",
            annotations: {
              inputType: "hidden",
            },
          },
        ],
      },
    },
  ],
  mockProperties: {
    gtmId: "GTM-XXXXXX",
    creativeBaseUrl: "https://creative.zuuvi.test:3001",
  }
});

export const { kcContext } = getKcContext();

export type KcContext = NonNullable<ReturnType<typeof getKcContext>["kcContext"]>;
